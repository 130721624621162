import { FC, useEffect, useMemo, useState } from 'react';
import { StyledShopProductDetailsGrid, StyledShopProductDetailsPageWrapper } from './shop-product-details-page.styled';
import { CenteredBlock } from '../../structural-blocks/centered-block.component';
import { InformationSection } from './information-section/information-section.component';
import { CarouselSection } from './carousel-section/carousel-section.component';
import { ShopProductDetails } from '../../../lib/api/models/shop';
import { useRouter } from 'next/router';
import { PdpUspTemplateBar } from '../../product-details/pdp-usp-template-bar/pdp-usp-template-bar.component';
import { umbraco } from '../../../lib/api';
import { ShopPdpUspBar } from '../../../lib/api/models/umbraco/product-details';
import { useGtmTracking } from '../../../gtm-tracking/hooks/use-gtm-tracking';
import { useRelewiseTracking } from '../../../hooks/relewise/use-relewise-tracking';

type Props = {
    product: ShopProductDetails;
    productFamily: Array<ShopProductDetails>;
    page: umbraco.ShopProductDetailsPage;
};

export const ShopProductDetailsPage: FC<Props> = ({ product, productFamily, page }) => {
    const [selectedVariant, setSelectedVariant] = useState(product);
    const { trackShopEvents } = useGtmTracking();
    const tracker = trackShopEvents();
    const { trackProductView } = useRelewiseTracking();

    const router = useRouter();

    const setNewVariant = (variant: ShopProductDetails) => {
        setSelectedVariant(variant);
        router.push(`/${variant.url}`, undefined, {
            shallow: true,
        });
    };

    const filteredUspBarFromCms: ShopPdpUspBar | undefined = useMemo(() => {
        if (!product) {
            return undefined;
        }

        return page.uspBarList.find((x) => x.productType === product.productType);
    }, [page.uspBarList, product]);

    useEffect(() => {
        tracker.clearEcommerceObject();
        tracker.pdpView(product);
        trackProductView(product.entityId.toString());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product]);

    return (
        <>
            <StyledShopProductDetailsPageWrapper>
                <CenteredBlock>
                    <StyledShopProductDetailsGrid>
                        <CarouselSection images={selectedVariant.resources} />
                        <InformationSection
                            product={selectedVariant}
                            productFamily={productFamily}
                            setSelectedVariant={(variant) => setNewVariant(variant)}
                        />
                    </StyledShopProductDetailsGrid>
                </CenteredBlock>
            </StyledShopProductDetailsPageWrapper>
            <PdpUspTemplateBar uspBar={filteredUspBarFromCms} />
        </>
    );
};
