import { useMemo } from 'react';
import { CompareCarsLeasingTypesModal, LeasingComparisonRow } from '../../../../lib/api/models/umbraco/product-details';
import { ProductDetailsStore } from '../../../../lib/state/hessel-site/product-details';
import { getAllIncludedPrice, getPriceForAllEquipment } from '../../../../lib/state/hessel-site/product-details/price.helper';
import { MEDIA_URL } from '../../../../utils/environment-constants';
import { PdpResource } from '../../../../lib/view-models/vehicle';
import { VehiclePurchaseType } from '../../../../lib/api/models/hessel-api/hire';
import { HighlightedText, StyledText } from './cars-leasing-types-modal.styled';
import { formatPrice, isNullOrEmpty } from '../../../../utils/helpers';
import { contentSpotTypes } from '../../../../lib/api';
import { Abbreviations, Units } from '../../../../constants/units-and-abbreviations';
import { contactForPriceLabelText } from '../../../../constants/site-consts';

type CompareLeasingTypeTable = {
    firstColumnHeader?: string;
    firstColumnSubheader?: string;
    rows: Array<contentSpotTypes.ComparisonTableRow>;
    columns: Array<contentSpotTypes.ComparisonTableColumn>;
};

type UseCarLeasingTypesModalReturn = {
    hireData: VehiclePurchaseType['hire'] | undefined;
    hirePrices: ReturnType<typeof getAllIncludedPrice>;
    privateLeasingData: VehiclePurchaseType['privateLeasing'] | undefined;
    privateLeasingPrices: ReturnType<typeof getAllIncludedPrice>;
    carouselItems: PdpResource[];
    greenOwnerFee: string | undefined;
    comparisonTableData: CompareLeasingTypeTable;
};

export const useCarLeasingTypesModal = (modalSettings: CompareCarsLeasingTypesModal): UseCarLeasingTypesModalReturn => {
    const { selectedProduct, selectedColor, extraEquipmentPackagePriceSum, extraEquipmentPriceSum, optionalEquipmentPriceSum } =
        ProductDetailsStore.useStoreState((state) => state);

    const { hireData, hirePrices, privateLeasingData, privateLeasingPrices, greenOwnerFee } = useMemo(() => {
        return {
            hireData: selectedProduct?.purchaseTypes.hire,
            hirePrices: {
                price: (selectedProduct?.purchaseTypes.hire.baseMonthlyPrice || 0) + (selectedColor?.monthlyHirePrice || 0),
                monthly: true,
                downPayment: 0,
            },
            privateLeasingData: selectedProduct?.purchaseTypes.privateLeasing,
            privateLeasingPrices: {
                price: (selectedProduct?.purchaseTypes.privateLeasing.baseMonthlyPrice || 0) + (selectedColor?.monthlyPrivateLeasingPrice || 0),
                monthly: true,
                downPayment: selectedProduct?.purchaseTypes.privateLeasing.downPayment || 0,
            },
            greenOwnerFee: selectedProduct?.specifications.greenOwnershipFee,
        };
    }, [selectedProduct, selectedColor]);

    const equipmentWithoutPrivateLeasingPriceSelected = useMemo(() => {
        const eq = selectedProduct?.extraEquipment['Car Privat Leasing'].find((x) => x.isSelected && x.monthlyPrivateLeasingPrice === -1);
        const eqp = selectedProduct?.extraEquipmentPackages['Car Privat Leasing'].find((x) => x.isSelected && x.monthlyPrivateLeasingPrice === -1);
        if (eq !== undefined || eqp !== undefined) return true;
        return false;
    }, [selectedProduct?.extraEquipment, selectedProduct?.extraEquipmentPackages]);

    const carouselItems = useMemo(() => {
        return (
            modalSettings?.descriptionMedia?.map((x) => {
                if (x.media.type === 'image' || x.media.extension?.toLowerCase() === 'webp') {
                    return {
                        type: 'image',
                        url: MEDIA_URL + x.media.src,
                        caption: x.caption,
                    } as PdpResource;
                }

                if (x.media.type === 'file') {
                    return {
                        type: 'video',
                        url: MEDIA_URL + x.media.src,
                        caption: x.caption,
                    } as PdpResource;
                }

                throw new Error('Unknown media type');
            }) || []
        );
    }, [modalSettings]);

    const generateRow = (rowData: LeasingComparisonRow) => ({
        title: rowData.label,
        description: rowData.details,
        cells: [
            {
                icon: rowData.operationalLeasing ? modalSettings.availableIcon : modalSettings.unavailableIcon,
            },
            {
                icon: rowData.financialLeasing ? modalSettings.availableIcon : modalSettings.unavailableIcon,
            },
        ],
    });

    const textSettings = modalSettings?.textSettings?.[0] ?? undefined;

    const hirePricePerKmIncludedInAgreement = hireData
        ? (hireData.baseMonthlyPrice * 6 + hirePrices.downPayment + (hireData.establishmentFee ?? 0)) /
          (hireData.kilometersPerMonth * hireData.durationFrom)
        : -1;

    const privateLeasingPricePerKmIncludedInAgreement =
        privateLeasingData && privateLeasingData.kilometersPerMonth
            ? (privateLeasingData.baseMonthlyPrice * privateLeasingData.durationMonths +
                  privateLeasingPrices.downPayment +
                  (privateLeasingData.establishmentFee ?? 0)) /
              (privateLeasingData.kilometersPerMonth * privateLeasingData.durationMonths)
            : -1;

    const allSelectedEquipmentSum = extraEquipmentPackagePriceSum + extraEquipmentPriceSum + optionalEquipmentPriceSum;

    const leasingPerMonthRow: contentSpotTypes.ComparisonTableRow = {
        title: textSettings?.leasingydelsePrMdr ?? 'Leasingydelse pr. md. (1)',
        description: '',
        cells: [
            {
                text: <StyledText>{`${formatPrice(hirePrices.price)} ${Abbreviations.KR_SLASH_MD}`}</StyledText>,
            },
            {
                text: <StyledText>{`${formatPrice(privateLeasingPrices.price)} ${Abbreviations.KR_SLASH_MD}`}</StyledText>,
            },
        ],
    };

    const extraAndOptionalEquipmentRow = {
        title: 'Ekstraudstyr og tilvalg pr. md.',
        description: !isNullOrEmpty(textSettings?.ekstraudstyrOgTilvalgPrMd)
            ? textSettings.ekstraudstyrOgTilvalgPrMd
            : 'Priserne vil opdatere sig automatisk i takt med det ekstraudstyr og de tilvalg, du foretager. Du vil muligvis opleve, at ikke alt ekstraudstyr og tilvalg er tilgængelige for begge leasingtyper, som betyder, at priserne for begge leasingtyper ikke nødvendigvis opdateres sideløbende.',
        cells: [
            {
                text: (
                    <StyledText>{`${selectedProduct ? formatPrice(getPriceForAllEquipment(selectedProduct, 'Car HiRE')) : -1} ${
                        Abbreviations.KR_SLASH_MDR
                    }`}</StyledText>
                ),
            },
            {
                text: (
                    <StyledText>
                        {equipmentWithoutPrivateLeasingPriceSelected
                            ? `${contactForPriceLabelText}`
                            : `${selectedProduct ? formatPrice(getPriceForAllEquipment(selectedProduct, 'Car Privat Leasing')) : -1} ${
                                  Abbreviations.KR_SLASH_MDR
                              }`}
                    </StyledText>
                ),
            },
        ],
    };

    const leasingAndEquipmentSumRow =
        allSelectedEquipmentSum > 0 || equipmentWithoutPrivateLeasingPriceSelected
            ? [leasingPerMonthRow, extraAndOptionalEquipmentRow]
            : [leasingPerMonthRow];

    const comparisonTableData: CompareLeasingTypeTable = {
        columns: [
            {
                headerText: modalSettings?.hireColumnHeader ?? 'Hessel HiRE',
                cta: [],
            },
            {
                headerText: modalSettings?.privateLeasingColumnHeader ?? 'Privateleasing',
                cta: [],
            },
        ],
        rows: [
            {
                title: textSettings?.prisPrKilometerInkluderetIAftalen ?? 'Pris pr. kilometer inkluderet i aftalen',
                description: '',
                cells: [
                    {
                        text:
                            hireData && hireData?.kilometersPerMonth ? (
                                <HighlightedText>
                                    {formatPrice(hirePricePerKmIncludedInAgreement, 2)}
                                    &nbsp;kr.*
                                </HighlightedText>
                            ) : (
                                '-'
                            ),
                    },
                    {
                        text:
                            (privateLeasingData && privateLeasingData?.kilometersPerMonth) || equipmentWithoutPrivateLeasingPriceSelected ? (
                                <HighlightedText>
                                    {equipmentWithoutPrivateLeasingPriceSelected
                                        ? `${contactForPriceLabelText}`
                                        : `${formatPrice(privateLeasingPricePerKmIncludedInAgreement, 2)} kr.`}
                                </HighlightedText>
                            ) : null,
                    },
                ],
            },

            ...leasingAndEquipmentSumRow,

            {
                title: textSettings?.udbetaling ?? 'Udbetaling',
                description: '',
                cells: [
                    {
                        text: <StyledText>{`${formatPrice(hirePrices.downPayment)} kr.`}</StyledText>,
                    },
                    {
                        text: <StyledText>{`${formatPrice(privateLeasingPrices.downPayment)} kr.`}</StyledText>,
                    },
                ],
            },
            {
                title: textSettings?.etableringsomkostninger ?? 'Etableringsomkostninger',
                description: '',
                cells: [
                    {
                        text: <StyledText>{`${formatPrice(hireData?.establishmentFee ?? 0)} kr.`}</StyledText>,
                    },
                    {
                        text: <StyledText>{`${formatPrice(selectedProduct?.purchaseTypes.privateLeasing.establishmentFee ?? 0)} kr.`}</StyledText>,
                    },
                ],
            },
            {
                title: textSettings?.inkluderedeKilometerPrMdr ?? 'Inkluderede kilometer pr. md. (1)',
                description: '',
                cells: [
                    {
                        text: <StyledText>{`${formatPrice(hireData?.kilometersPerMonth ?? 0)} ${Units.km}`}</StyledText>,
                    },
                    {
                        text: <StyledText>{`${formatPrice(Math.floor((privateLeasingData?.kilometersPerYear || 0) / 12))} ${Units.km}`}</StyledText>,
                    },
                ],
            },
            {
                title: textSettings?.ekstraBetalingForOverkoerteKilometer ?? 'Ekstrabetaling for overkørte kilometer',
                description: '',
                cells: [
                    {
                        text: <StyledText>{`${hireData?.pricePerOvertakingKilometers} kr.`}</StyledText>,
                    },
                    {
                        text: <StyledText>{contactForPriceLabelText}</StyledText>,
                    },
                ],
            },
            {
                title: textSettings?.leasingperiode ?? 'Leasingperiode',
                description: '',
                cells: [
                    {
                        text: (
                            <StyledText>
                                {hireData?.durationFrom}-{hireData?.durationTo} mdr.
                            </StyledText>
                        ),
                    },
                    {
                        text: <StyledText>{`${privateLeasingData?.durationMonths} mdr.`}</StyledText>,
                    },
                ],
            },
            {
                title: textSettings?.aftalenInkludererServiceOgReparationer ?? 'Aftalen inkluderer service og reparationer*',
                description: '',
                cells: [
                    {
                        icon: modalSettings?.aftalenInkludererServiceOgReparationer ? modalSettings.availableIcon : modalSettings?.unavailableIcon,
                    },
                    {
                        icon: modalSettings?.aftalenInkludererServiceOgReparationerLeasing
                            ? modalSettings.availableIcon
                            : modalSettings?.unavailableIcon,
                    },
                ],
            },
            {
                title: textSettings?.aftalenInkludererEnFabriksnyBil ?? 'Aftalen inkluderer en fabriksny bil',
                description: '',
                cells: [
                    {
                        icon: modalSettings?.aftalenInkludererEnFabriksnyBil ? modalSettings.availableIcon : modalSettings?.unavailableIcon,
                    },
                    {
                        icon: modalSettings?.aftalenInkludererEnFabriksnyBilLeasing ? modalSettings.availableIcon : modalSettings?.unavailableIcon,
                    },
                ],
            },
            {
                title: textSettings?.bilenStaarMedEkstraudstyrFraStart ?? 'Bilen står med ekstraudstyr fra start',
                description: '',
                cells: [
                    {
                        icon: modalSettings?.bilenStaarMedEkstraudstyrFraStart ? modalSettings.availableIcon : modalSettings?.unavailableIcon,
                    },
                    {
                        icon: modalSettings?.bilenStaarMedEkstraudstyrFraStartLeasing ? modalSettings.availableIcon : modalSettings?.unavailableIcon,
                    },
                ],
            },
            {
                title: textSettings?.aftalenIndeholderAnsvarOgKaskoforsikring ?? 'Aftalen indeholder ansvar- og kaskoforsikring',
                description: '',
                cells: [
                    {
                        icon: modalSettings?.aftalenIndeholderAnsvarOgKaskoforsikring ? modalSettings.availableIcon : modalSettings?.unavailableIcon,
                    },
                    {
                        icon:
                            modalSettings?.aftalenIndeholderAnsvarOgKaskoforsikringLeasing ||
                            selectedProduct?.purchaseTypes.privateLeasing.leasingTerms?.insurance
                                ? modalSettings.availableIcon
                                : modalSettings?.unavailableIcon,
                    },
                ],
            },
            {
                title: textSettings?.aftalenIndholderGroenEjerafgift ?? 'Aftalen indholder grøn ejerafgift',
                description: '',
                cells: [
                    {
                        text: greenOwnerFee ? <StyledText>Inkluderet i aftalen</StyledText> : undefined,
                    },
                    {
                        text: greenOwnerFee ? (
                            <StyledText>
                                {Math.round(+greenOwnerFee / 6)} {Abbreviations.KR_SLASH_MD}
                            </StyledText>
                        ) : (
                            <StyledText>Inkluderet i aftalen</StyledText>
                        ),
                    },
                ],
            },
            ...(modalSettings?.leasingComparisonRows?.map((x) => generateRow(x)) || []),
        ],
    };

    return { hireData, hirePrices, privateLeasingData, privateLeasingPrices, carouselItems, greenOwnerFee, comparisonTableData };
};
